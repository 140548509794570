@import "./colors.scss";

header {
  top: 0;
  position: sticky;
  overflow: hidden;
  background-color: $darkGrey;
}

nav {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
}

nav.desktop {
  justify-content: flex-start;
}

nav ul {
  display: none;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

nav ul.active {
  display: block;
  width: 100%;
}

nav.desktop ul {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  align-items: center;
  width: 100%;
}

// Add margins to hamburger menu button
nav div {
  margin: 15px 20px;
}

// Hide hamburger menu button when desktop
nav.desktop div {
  display: none;
}

nav a {
  display: block;
  color: $text;
  line-height: 60px;
  margin: 0 16px;
  height: 60px;
  text-decoration: none;
  font-family: 'Open Sans';
  font-size: 18px;
  box-sizing: border-box;
  cursor: pointer;
}

// hover styling
nav.desktop li a:hover {
  text-decoration: none;
  border-bottom: 3px solid white;
}

// section of page is active
nav.desktop li a.active {
  border-bottom: 3px solid white;
}

nav.desktop li a.social:hover {
  border-bottom: none;
}

#header-logo {
  margin: 0;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0 );
  height: 60px;
  padding: 10px 0;
  box-sizing: border-box;
  text-align: center;
}

nav img {
  height: 100%;
}

nav .social {
  padding: 15px 0;
  border: none;
  line-height: normal;
}

// the element to break to right align
#break {
  flex-grow: 1;
  text-align: right;
}


