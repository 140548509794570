@import "./colors.scss";
@import "./navbar.scss";

$headerSize: 60px;

// styling for particles background
canvas {
  position: fixed;
  z-index: -1;
}

body {
  margin: 0;
  background-color: $background;
}

section {
  display: block;
  margin-bottom: $headerSize;
  min-height: 100vh;
}

.section-content {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 20px;
  padding-bottom: 50px;
}

// offset each section for the height of the header
section::before {
  display: block; 
  content: " "; 
  margin-top: -$headerSize;
  height: $headerSize; 
  visibility: hidden; 
  pointer-events: none;
}

li {
  font-family: 'Open Sans';
  color: $text;
  // text-align: left;
}

h1 {
  font-family: "Francois One";
  font-size: 40px;
  color: $text;
}

h2 {
  text-align: center;
  font-family: 'Francois One';
  font-size: 40px;
  color: $text;
}

h3 {
  font-family: 'Open Sans';
  color: $text;
  font-weight: bold;
  font-size: 25px;
}

p {
  color: $text;
  font-family: 'Open Sans';
  font-size: 16px;
  text-align: left;
}

a {
  color: $text;
}

a:visited {
  color: $text;
}

// HOME PAGE
#home {
  text-align: center;
  display: block;
}

#home h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  text-align: center;
}

#caret {
  position: absolute;
  height: 25px;
  bottom: 50px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

// PROJECTS PAGE

.card {
  padding: 10px;
  margin: 20px 0;
  background-color: $card;
  border-radius: .5rem;
  display: flex;
  flex-flow: row wrap;
  text-decoration: none;
}

.card h3 {
  margin: 0;
}

.image-container img {
  max-width: 100%;
  height: auto;
}

.card .image-container {
  flex: 1 1 200px;
  margin: 10px;
}

.card .description {
  flex: 2 1 200px;
  margin: 10px;
  display: flex;
  flex-direction: column;
}

.description p {
  margin-block-end: 0;
}

.card .caption {
  text-transform: uppercase;
  font-size: 14px;
}

.card .action-link {
  margin-top: auto;
  text-decoration: none;
}

.card .action-link p {
  margin: 0;
  margin-top: 10px;
  text-align: right;
}

.card .action-link p:hover {
  text-decoration: underline;
  cursor: pointer;
}

// ABOUT PAGE
#about {
  background-color: $darkOcean;
}

#about .section-content {
  max-width: 600px;
}

a {
  text-decoration: none;
  font-weight: 600;
}

a:hover {
  text-decoration: underline;
}

#about img {
  display: block;
  height: 200px;
  border-radius: 100%;
  margin: 20px auto;
}

#about h3 {
  margin-bottom: 0;
}

#about ul {
  padding-left: 20px;
}

#about ul li {
  padding: 0.25em 0;
}

// FOOTER

footer {
  margin: 60px;
}

footer p {
  text-align: center;
}