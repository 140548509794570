$darkGrey: #202020;
$darkOcean: #184554;
$white: #ffffff;
$black: #000000;

$background: $darkGrey;
$accentDark: $darkOcean;
$text: $white;
$card: #303030;
$cardHover: #404040;
